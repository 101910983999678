import React from "react"

import { ElementType, HeadsetSeriesWithSettingsType } from "../utils/types"
import HeadsetCard from "../components/HeadsetCard"

type Props = {
  list: ElementType[] | HeadsetSeriesWithSettingsType[]
  onSelect: (id: number) => Promise<void>
  series?: boolean
}

export const ColumnList = (props: Props) => {
  const { list, onSelect, series } = props

  const filteredElements = list.filter((e) => !e.disabled)
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-5 gap-y-10">
      {filteredElements.length === 0 ? (
        <p>No products matched the criteria</p>
      ) : (
        filteredElements.map((element) => (
          <HeadsetCard
            key={element.id}
            onSelect={onSelect}
            {...element}
            series={series}
            className="cursor-pointer"
          />
        ))
      )}
    </div>
  )
}
